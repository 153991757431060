<template>
	<div class="collection">
			<rxNavBar title="我的收藏" androidOrIOSFlag="true"></rxNavBar>
		<div class="orderList">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<!--列表-->
				<div v-if="swi"><common-empty></common-empty> </div>
				<van-list  v-model="loading" :finished="finished" loading-text="加载中" finished-text="已经到底了" @load="onLoad" v-if="!swi">
					<div v-for="(item,index) in finishList" :key="index" class="oneList">
						<div class="oneListTop" @click="houseDtailsClick({row:item})">
							<div class="oneListTop-image">
								<img :src="item.roomPhotoPath?item.roomPhotoPath:errorImg" style="border-radius: 8px" />
								<div class="oneListTop-image-already" v-if="item.houseChildType=='8'">
									<div class="small-text">已租</div>
								</div>
							</div>
							<div class="oneListTop-text">
								<div class="oneListTop-text-already" v-if="item.houseChildType=='8'"></div>
								<div :class="item.houseChildType=='8'?'oneListTop-text-areaGray':'oneListTop-text-area'">
									{{item.roomFuzzyAddress}}</div>
								<div :class="item.houseChildType=='8'?'oneListTop-text-detailGray':'oneListTop-text-detail'">
									<div class="oneListTop-text-detail-info">{{item.roombusinessCircleName}}</div>
									<div :class="item.houseChildType=='8'?'oneListTop-text-detail-lineGray':'oneListTop-text-detail-line'">
									</div>
									<div class="oneListTop-text-detail-info">{{item.roomArea}}㎡</div>
									<div :class="item.houseChildType=='8'?'oneListTop-text-detail-lineGray':'oneListTop-text-detail-line'">
									</div>
									<div class="oneListTop-text-detail-info">{{item.roomFloor}}层</div>
								</div>
								<div>
									<div :class="item.houseChildType=='8'?'oneListTop-text-detail-moneyGray':'oneListTop-text-detail-money'">
										{{item.roomRent}}元
										<span class="font">/月</span>
									</div>
								</div>
								<div :class="item.houseChildType=='8'?'oneListTop-text-detail-labelGray':'oneListTop-text-detail-label'">
									<span style="margin-left: 3px;margin-right: 3px">{{item.houseType}}</span>
								</div>
								<div v-for="feature in item.roomCharacteristicList" :class="item.houseChildType=='8'?'oneListTop-text-detail-labelGray01':'oneListTop-text-detail-label01'">
									<span style="margin-left: 3px;margin-right: 3px">{{feature.roomCharacteristicName}}</span>
								</div>
							</div>
						</div>
					</div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	import {
		NavBar,
		PullRefresh,
		List
	} from "vant";
	import {
		roomCollectionList
	} from "../../../getData/getData";
	import {
		checkAndroid,
		checkIOS,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Cookies from "js-cookie";

	//调用android方法，传参roomId
	function collectionToAndroid(roomId) {
		window.himi.showRoomDetail(roomId);
	}

	export default {
		name: "myCollection",
		data() {
			return {
				a: true,
				list: [],
				finishList: [],
				loading: false,
				finished: false,
				refreshing: false,
				currentPage: 1,
				pageCount: '',
				totalPage: '',
				swi: false,
				errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
			};
		},
		//设置背景颜色
		mounted() {
			// document
			// 	.querySelector("body")
			// 	.setAttribute("style", "background-color:#FFFFFF");
			this.getList();
		},

		components: {
			[NavBar.name]: NavBar,
			[PullRefresh.name]: PullRefresh,
			[List.name]: List,
			rxNavBar,
		},
		methods: {

			//返回上一页
			leftReturn() {
				this.$router.go(-1);
			},
			getList(currentPage, numberPage) {
				currentPage = currentPage || 1
				numberPage = numberPage || 10
				let that = this;
				let initData = {
					user_id: globaluserId(),
					currentPage: currentPage,
					numberPage: numberPage
				};
				roomCollectionList(initData).then(response => {
					responseUtil.dealResponse(that, response, () => {
						if (response.data.data.pageCount == 0) {
							that.swi = true
							that.loading = false;
							that.finished = true;
						}else{
							that.swi = false
							// console.log(response.data.data)
							this.currentPage++
							that.list = response.data.data.roomList
							that.finishList = that.finishList.concat(that.list)
							if (that.totalPage == '' && that.pageCount == '') {
								that.totalPage = response.data.data.totalPage
								that.pageCount = response.data.data.pageCount
							}
						}

					});
				});
			},
			//点击进入房源详情页面
			houseDtailsClick(e) {
				let roomId = e.row.room_id;
				//APP传值*******begin*********
				if (checkAndroid()) {
					collectionToAndroid(roomId);
				} else if (checkIOS()) {
					console.log("对ios传参");
					window.webkit.messageHandlers.showRoomDetail.postMessage({body: roomId});
				}
				//APP传值*******end*********

			},
			onLoad() {
				setTimeout(() => {
					if (this.refreshing) {
						this.finishList = [];
						this.refreshing = false;
						this.currentPage = 1
					}
					if (this.currentPage <= this.totalPage) {
						this.getList(this.currentPage)
					}
					this.loading = false;
					if (this.finishList.length >= this.pageCount) {
						this.finished = true;
					}
				}, 1000);
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false;
				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true;
				this.onLoad();
			},
		}
	};
</script>

<style scoped>
	.collection {
		overflow: hidden;
	}
	.orderList {
		height: auto;
		width: 92%;
		margin: 0 auto;
	}

	.oneList {
		width: 100%;
		height: auto;
		margin: 20px 0 0 0px;
		background-color: rgba(255, 255, 255, 1);
		padding: 10px 0px;
		border-radius: 8px;
	}

	.oneListTop {
		width: 355px;
		height: auto;
		/*border-bottom: 1px solid rgba(239, 239, 239, 1);*/
		display: flex;
		overflow: hidden;
	}

	.oneListTop-image {
		position: relative;
		width: 125px;
		height: 93.75px;
		margin: 0px 8px 0 10px;
		border-radius: 8px;
		border: none;
		background-color: #d8d8d8;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.oneListTop-image-already {
		position: absolute;
		z-index: 99;
		top: 0.5px;
		width: 29px;
		height: 17px;
		line-height: 17px;
		border-radius: 8px 8px 8px 0;
		border: none;
		background-color: #999999;
		color: #f9f9f9;
		font-size: 12px;
		text-align: center;
		font-family: PingFangSC-Regular;
	}

	.oneListTop-text {
		width: 227px;
		height: 100%;
	}

	.oneListTop-text-already {
		color: rgba(216, 216, 216, 1);
	}

	.oneListTop-text-area {
		width: 205px;
		height: 21px;
		/*margin-top: 5px;*/
		line-height: 21px;
		color: #222222;
		font-size: 15px;
		font-weight: 600;
		text-align: left;
		font-family: PingFangSC-Regular;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.oneListTop-text-areaGray {
		width: 205px;
		height: 21px;
		/*margin-top: 5px;*/
		line-height: 21px;
		color: rgba(216, 216, 216, 1);
		font-size: 15px;
		font-weight: 600;
		text-align: left;
		font-family: PingFangSC-Regular;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.oneListTop-text-detail {
		height: 17px;
		margin-top: 5px;
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
		display: flex;
		align-items: center;
	}

	.oneListTop-text-detailGray {
		height: 17px;
		margin-top: 5px;
		color: rgba(216, 216, 216, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
		display: flex;
		align-items: center;
	}

	.oneListTop-text-detail-line {
		width: 1px;
		height: 12px;
		background-color: rgba(102, 102, 102, 1);
		margin: 0 10px;
	}

	.oneListTop-text-detail-lineGray {
		width: 1px;
		height: 12px;
		background-color: rgba(216, 216, 216, 1);
		margin: 0 10px;
	}

	.oneListTop-text-detail-money {
		width: auto;
		height: 21px;
		margin-top: 2px;
		line-height: 21px;
		color: #ff5d3b;
		font-size: 16px;
		text-align: left;
		font-family: PingFangSC-Regular;

		/*font-weight: bold;*/
	}

	.oneListTop-text-detail-moneyGray {
		width: auto;
		height: 21px;
		margin-top: 2px;
		line-height: 21px;
		color: rgba(216, 216, 216, 1);
		font-size: 15px;
		text-align: left;
		font-family: PingFangSC-Regular;
		font-family: Oswald-Regular;
	}

	.oneListTop-text-detail-month {
		width: 15px;
		height: 14px;
		position: absolute;
		line-height: 21px;
		color: #ff5d3b;
		font-size: 10px;
		text-align: left;
		font-family: PingFangSC-Regular;
		margin-left: 60px;
		margin-top: -15px;
	}

	.oneListTop-text-detail-monthGray {
		width: 15px;
		height: 14px;
		position: absolute;
		line-height: 21px;
		color: rgba(216, 216, 216, 1);
		font-size: 10px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.oneListTop-text-detail-label {
		height: 14px;
		line-height: 16px;
		font-size: 10px;
		text-align: center;
		margin-top: 10px;
		float: left;
		color: #ff5d3b;
		font-family: PingFangSC-Regular;
		background-color: #fbded5;
		padding: 2px;
		-webkit-transform: scale(0.83);

	}

	.oneListTop-text-detail-labelGray {
		height: 14px;
		float: left;
		line-height: 16px;
		color: rgba(216, 216, 216, 1);
		font-size: 10px;
		text-align: center;
		font-family: PingFangSC-Regular;
		margin-top: 10px;
		color: #feb9a9;
		background-color: #fdede8;
		padding: 2px;
		-webkit-transform: scale(0.83);
	}

	.oneListTop-text-detail-label01 {
		height: 14px;
		line-height: 16px;
		font-size: 10px;
		text-align: center;
		margin-top: 10px;
		float: left;
		color: #999999;
		font-family: PingFangSC-Regular;
		background-color: #f0f0f0;
		padding: 2px;
		-webkit-transform: scale(0.83);
	}

	.oneListTop-text-detail-labelGray01 {
		height: 14px;
		float: left;
		margin-top: 0.7px;
		line-height: 16px;
		font-size: 10px;
		text-align: center;
		font-family: PingFangSC-Regular;
		margin-top: 10px;
		color: #d8d8d8;
		background-color: #f8f8f8;
		padding: 2px;
		-webkit-transform: scale(0.83);
	}

	.small-text {
		width: 24px;
		height: 17px;
		margin-left: 1px;
		margin-top: 0.7px;
		font-size: 12px;
		-webkit-transform: scale(0.83);
	}

	.font {
		font-size: 10px;
		margin-left: 7px;
	}


</style>
